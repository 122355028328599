
<template>
  <div id="app" class="promotion-page ">
    <!-- 顶部布局 -->
    <header >
     
    </header>

    <!-- 中部布局 -->
    <main>
      <!-- 第一个模块 -->
      <div class="module1">
        <div class="imgdiv">
          <img src="../assets/renzheng.png" alt="">
          <img src="../assets/yuepa.png" alt="">
          <img src="../assets/liaomei.png" alt="">
          <a href="https://t.me/fff8888fff" > <img src="../assets/xiatan.png" alt=""></a>
        </div>
       
        

      </div>

      <!-- 第二个模块 -->
      <div class="module2">
        <div class="biaotu"> 
          <img src="../assets/biaotu.png" alt=""> 
        </div>
        <div class="danbao">
          <img src="../assets/danbao.png" alt=""> 
        </div>
      </div> 
        <div class="carousel">
        <!-- 这里添加你的轮播图组件 -->
        <!-- <img src="../assets/bj1.png" class="carousel-image" />
          <img src="../assets/bj2.png" class="carousel-image" /> -->
        <img :src="imgurl" class="carousel-image" />
 
      </div>

    </main>

    <!-- 底部布局 -->
    <footer>
      <div class="bottom">
        <img src="../assets/anzhuo.png" alt="" @click="downloadApp" >
        <img src="../assets/ios.png" alt="" @click="iosTg">
      </div>
 
    </footer>
  </div>
</template>

  
<style scoped>

.promotion-page {

  background-image: url('../assets/bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; /* 使容器高度等于视口高度，确保背景铺满整个屏幕 */
  display: flex;
  flex-direction: column; /* 或者你希望的其他布局方式 */
  

}
 

header {
   height: 10vh;
  color: white; /* 顶部文字的颜色 */
  padding: 10px;
}

main {
  flex-grow: 1; /* 让中部充满剩余空间 */ 
}

.module1,
.module2 {
  flex-grow: 1; /* 两个模块平分中部空间 */ 
  
}

.module1 .imgdiv img{
  margin-top: 1vh;
  width: 22vw;
  padding: 3px;
}

.module2 .biaotu img{
  margin-top: 8vh;
  width: 91vw;
  height: 20vh;
}
.module2 .danbao img{
  margin-top: 2vh;
  width: 91vw;
 
}

.carousel img{
  margin-top: 8vh;
  width: 99vw;
  height: 30vh;
}


footer {
  flex-direction: column;
  background-image: url('../assets/bottoms.png');
  /* 替换为你的背景图片路径 */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
  color: white; /* 底部文字的颜色 */ 
  flex-grow: 1; /* 让中部充满剩余空间 */
  display: flex;
  height: 16vh;
}

.bottom{
  margin-top: auto;
  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: flex-end;
    
}
.bottom  img{ 
 
  padding: 10px;
  width: 16vh;
}

 

</style>
  
<script>
import { ref } from 'vue'
import router from '@/router/index'
import { useRoute } from 'vue-router'
import axios from 'axios';
export default {
  name: 'indexView',

  setup() {


    const images = [
      "/011.png",
      "/022.png",
      "/033.png",
      "/044.png",
      "/055.png",
    ];

    var currentIndex = 0;

    const imgurl=ref("");

    
    const currentImage = () => {
      imgurl.value=  images[currentIndex];
    }

    currentImage();

    const   startCarousel = () => {
      setInterval(() => {
        currentIndex = (currentIndex + 1) % images.length;
        currentImage();
      }, 3000); 
    };
    startCarousel();

    const route = useRoute()

    const key = route.query.code;

    const iosTg = () => {
      router.push({ path: '/ios' })
    }

    const downloadApp = () => {
      if (key) {
        window.location.href = "/shise.apk?code=" + key
      } else {
        window.location.href = "/shise.apk"
      }
    }


    const fetchData = () => {
      var url = '/visits/addVisits';
      if (key) {
        url = "/visits/addVisits?code=" + key
      }

      axios.get(url)
        .then(response => {
          // 请求成功，将数据存储在data属性中
          //this.data = response.data;
        })
        .catch(error => {
          // 请求失败，处理错误
          console.error('Error fetching data:', error);
        });
    }

    fetchData();

    return {
      iosTg,
      downloadApp,
      imgurl,
    }
  }



}
</script>
  