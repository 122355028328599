<template>
    <div class="promotion">
        <div >
            <img src="../assets/logo.png" />
        </div>
       
        <div >
            <img src="../assets/wez.png" class="title_img" />
        </div>

        <div >
            <a href="/shise.mobileconfig">
            <img src="../assets/2.png" class="title_img" />
           </a>
        </div>

      

        <div class="text_div">
             1. 安装轻量版所需描述文件。此描述文件已通过苹果官方认证, 安全可靠。
        </div>
        <div >
            <img src="../assets/23.png" class="title_img" />
        </div>
        <div class="text_div">
             2. 点击允许后，首先在桌面主屏幕找到 “设置” 进入设置点击<span>“已下载描述文件”</span>
        </div>

        <div >
            <img src="../assets/ffg.png" class="title_img" />
        </div>
        <div class="text_div">
             3. 最后按照提示一路点击右上角的<span>“安装”</span> 或 <span>“下一步”</span>即可完成安装
        </div>

        <div >
            <img src="../assets/52.png" class="title_img" />
        </div>
        <div class="text_div">
            如果您设置了锁屏密码，安装时需要输入锁屏密码后才能继续
        </div>
 

    </div>
    
</template>

<style>
    .promotion{
        max-width: 800px;
        margin: 0 auto; /* 居中显示 */
        display: flex;
        flex-direction: column; 
        background-image: url('../assets/iosbg.png'); /* 替换为你的背景图片路径 */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 10px; 
        text-align: center;
    }

   
    .title_img{
        max-width: 80%;
         height: auto;
         margin-top: 15px;
    }
 
    .text_div{
        color: #ffffff;
        font-size: 16px;
        margin-top: 15px;
        text-align: left;
    }

    .text_div span{
        color: #ced00c;
    }



  
       
</style>

<script>


</script>